import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import "../../styles/variables.scss"


function Seo({ description, lang, meta, title, canonical }) {
  const { site, strapiSiteSettings } = useStaticQuery(
    graphql`
        query {
          site {
            siteMetadata {
              title
              description
              author
            }
          }

          strapiSiteSettings{
            SiteTitle
            SiteDescription
            MetaTags{
              MetaName
              MetaContent
            }
          }
        }
      `
  )

  const metaDescription = description || strapiSiteSettings.SiteDescription
  var metaTitle = title || strapiSiteSettings.SiteTitle
  var titleTemplate = metaTitle

  if (title !== "") {
    if (title.includes(strapiSiteSettings.SiteTitle)) {
      metaTitle = title
    } else {
      metaTitle = title + " | " + strapiSiteSettings.SiteTitle
    }
  }

  if (canonical != undefined && canonical.length > 0 && canonical.charAt(canonical.length - 1) != '/') {
    canonical = canonical + '/'
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      // titleTemplate={titleTemplate}

      link={[
        {
          rel: "preconnect",
          href:
            "https://fonts.gstatic.com",
        },
        {
          rel: "preconnect",
          href:
            "https://cdn.jsdelivr.net",
        },
        {
          rel: "preconnect",
          href:
            "https://fonts.googleapis.com/",
        },

        {
          rel: "preload",
          href: "https://fonts.googleapis.com/css2?family=Roboto",
          as: "style"
        },

      ]}

      script={[

      ]}


    >
      <link rel="preconnect" href="https://cdn.jsdelivr.net" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link data-react-helmet="true" rel="preload" as="font" crossorigin="anonymous" type="font/woff2" href="https://fonts.googleapis.com/css?family=Roboto:300,300i,400,500,500i,600,700&display=optional"></link>
      <meta key="key-description" name="description" content={metaDescription} />
      <meta key="key-ogtitle" name="og:title" content={metaTitle} />
      <meta key="key-ogdescription" name="og:description" content={metaDescription} />
      <meta key="key-ogtype" name="og:type" content="website" />
      <meta key="key-description" name="twitter:card" content="summary" />
      <meta key="key-twittercreateor" name="twitter:creator" content={site.siteMetadata.author} />
      <meta key="key-twittertitle" name="twitter:title" content={metaTitle} />
      <meta key="key-twitterdesc" name="twitter:description" content={metaDescription} />
      {/** List of meta properties coming from strapi settings that will apply globally across all pages */

        strapiSiteSettings.MetaTags?.map((item, index) => {
          return (item.MetaName && item.MetaContent) ? (
            <meta key={`meta-key-` + index} name={item.MetaName} content={item.MetaContent} />
          ) : ''
        })
      }
      {
        meta?.map((item, index) => {
          return (item.MetaName && item.MetaContent) ? (
            <meta key={`meta-key-` + index} name={item.MetaName} content={item.MetaContent} />
          ) : ''
        })

      }
      {
        /** andrei: if canonical is populated in SEO - add the link href to it**/
        canonical ? <link rel="canonical" href={canonical} /> : ""
      }

    </Helmet>
  )
}

Seo.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
  title: ''
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
